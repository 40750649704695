import logo from "../../assets/logo.png";

export const Header = () => {
  return (
    <header className="header">
      <div className="header__wrapper">
        <svg
          className="header__svg--mob"
          width="425"
          height="89"
          viewBox="0 0 425 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_917_8676)">
            <path
              d="M0 -4H426V81L412.688 68.8571L399.375 81L386.062 68.8571L372.75 81L359.438 68.8571L346.125 81L332.812 68.8571L319.5 81L306.188 68.8571L292.875 81L279.562 68.8571L266.25 81L252.938 68.8571L239.625 81L226.312 68.8571L213 81L199.688 68.8571L186.375 81L173.062 68.8571L159.75 81L146.438 68.8571L133.125 81L119.812 68.8571L106.5 81L93.1875 68.8571L79.875 81L66.5625 68.8571L53.25 81L39.9375 68.8571L26.625 81L13.3125 68.8571L0 81V-4Z"
              fill="url(#paint0_linear_917_8676)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_917_8676"
              x="-4"
              y="-4"
              width="434"
              height="93"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_917_8676"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_917_8676"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_917_8676"
              x1="205.5"
              y1="0.857138"
              x2="205.5"
              y2="85.25"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFEDF3" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          className="header__svg--pc"
          width="1286"
          height="93"
          viewBox="0 0 1286 93"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_938_8717)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 0H430H856H1282V85L1268.69 72.8571L1255.38 85L1242.06 72.8571L1228.75 85L1215.44 72.8571L1202.12 85L1188.81 72.8571L1175.5 85L1162.19 72.8571L1148.88 85L1135.56 72.8571L1122.25 85L1108.94 72.8571L1095.62 85L1082.31 72.8571L1069 85L1055.69 72.8571L1042.38 85L1029.06 72.8571L1015.75 85L1002.44 72.8571L989.125 85L975.812 72.8571L962.5 85L949.188 72.8571L935.875 85L922.562 72.8571L909.25 85L895.938 72.8571L882.625 85L869.312 72.8571L856 85L842.688 72.8571L829.375 85L816.062 72.8571L802.75 85L789.438 72.8571L776.125 85L762.812 72.8571L749.5 85L736.188 72.8571L722.875 85L709.562 72.8571L696.25 85L682.938 72.8571L669.625 85L656.312 72.8571L643 85L629.688 72.8571L616.375 85L603.062 72.8571L589.75 85L576.438 72.8571L563.125 85L549.812 72.8571L536.5 85L523.188 72.8571L509.875 85L496.562 72.8571L483.25 85L469.938 72.8571L456.625 85L443.312 72.8571L430 85L416.688 72.8571L403.375 85L390.062 72.8571L376.75 85L363.438 72.8571L350.125 85L336.812 72.8571L323.5 85L310.188 72.8571L296.875 85L283.562 72.8571L270.25 85L256.938 72.8571L243.625 85L230.312 72.8571L217 85L203.688 72.8571L190.375 85L177.062 72.8571L163.75 85L150.438 72.8571L137.125 85L123.812 72.8571L110.5 85L97.1875 72.8571L83.875 85L70.5625 72.8571L57.25 85L43.9375 72.8571L30.625 85L17.3125 72.8571L4 85V0Z"
              fill="url(#paint0_linear_938_8717)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_938_8717"
              x="0"
              y="0"
              width="1286"
              height="93"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_938_8717"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_938_8717"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_938_8717"
              x1="1061.5"
              y1="4.85714"
              x2="1061.5"
              y2="89.25"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFEDF3" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
          </defs>
        </svg>
        <img src={logo} alt="logotype" className="header__logo" />
      </div>
    </header>
  );
};
