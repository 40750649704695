import supergra from "../../assets/supergra.png";
import logo777 from "../../assets/logo777.png";
import favbet from "../../assets/favbet.png";
import ggbet from "../../assets/ggbet.png";
import slotscity from "../../assets/slotscity.png";
import first from "../../assets/first.png";

export const Main = () => {
  const params = new URLSearchParams(document.location.search);
  const sub1 = params.get("sub1") ?? localStorage.getItem("sub1");
  const sub2 = params.get("sub2") ?? localStorage.getItem("sub2");
  const sub3 = params.get("sub3") ?? localStorage.getItem("sub3");
  const sub4 = params.get("sub4") ?? localStorage.getItem("sub4");
  const sub5 = params.get("sub5") ?? localStorage.getItem("sub5");
  const sub6 = params.get("sub6") ?? localStorage.getItem("sub6");
  const sub7 = params.get("sub7") ?? localStorage.getItem("sub7");
  const sub8 = params.get("sub8") ?? localStorage.getItem("sub8");
  const sub9 = params.get("sub9") ?? localStorage.getItem("sub9");

  if (sub1 !== null && sub1) {
    localStorage.setItem("sub1", sub1);
  }

  if (sub2 !== null && sub2) {
    localStorage.setItem("sub2", sub2);
  }

  if (sub3 !== null && sub3) {
    localStorage.setItem("sub3", sub3);
  }

  if (sub4 !== null && sub4) {
    localStorage.setItem("sub4", sub4);
  }

  if (sub5 !== null && sub5) {
    localStorage.setItem("sub5", sub5);
  }

  if (sub6 !== null && sub6) {
    localStorage.setItem("sub6", sub6);
  }

  if (sub7 !== null && sub7) {
    localStorage.setItem("sub7", sub7);
  }
  if (sub8 !== null && sub8) {
    localStorage.setItem("sub8", sub8);
  }
  if (sub9 !== null && sub9) {
    localStorage.setItem("sub9", sub9);
  }

  const clickHandler = (t, tgLink, offer) => {
    let e = "deep-link";
    let r = true;
    let n = {
      variables: {},
    };
    if (sub1 !== null) {
      n.variables["sub1"] = sub1;
    }
    if (sub2 !== null) {
      n.variables["sub2"] = sub2;
    }
    if (sub3 !== null) {
      n.variables["sub3"] = sub3;
    }
    if (sub4 !== null) {
      n.variables["sub4"] = sub4;
    }
    if (sub5 !== null) {
      n.variables["sub5"] = sub5;
    }
    if (sub6 !== null) {
      n.variables["sub6"] = sub6;
    }
    if (sub7 !== null) {
      n.variables["sub7"] = sub7;
    }
    if (sub8 !== null) {
      n.variables["sub8"] = sub8;
    }
    if (sub9 !== null) {
      n.variables["sub9"] = sub9;
    }
    let a = {
      context: { range: [], scope: {}, variables: n },
      messengers: [
        {
          key: "?",
          name: "telegram",
          parameter: "start",
          prefixes: ["tg://", "https://t.me/"],
        },
      ],
      delimiter: "|",
      url: "/api/i/store",
    };
    // document
    //   .getElementById("tg://resolve?start=ZGw6MTk5Mzc1&domain=sloto_ua_bot")
    //   .addEventListener("click", function (t) {
    // eslint-disable-next-line no-unused-expressions
    t.preventDefault(),
      (function (e) {
        let t = new XMLHttpRequest();
        // eslint-disable-next-line no-unused-expressions
        t.open("POST", "https://wq13531.customer.smartsender.eu/api/i/store"),
          t.setRequestHeader("Content-type", "application/json"),
          t.setRequestHeader("X-Requested-With", "XMLHttpRequest"),
          (t.onreadystatechange = function () {
            if (4 === this.readyState && 200 === this.status) {
              let t = JSON.parse(this.responseText);
              window.location.href = (function (e, t) {
                let r = a.messengers.find(function (t) {
                    return t.prefixes.find(function (t) {
                      return 0 === e.indexOf(t);
                    });
                  }),
                  n = e.split(r.key),
                  s = n[1].split("&");
                return (
                  s.forEach(function (e, n) {
                    let i = e.split("=");
                    if (r.parameter === i[0]) {
                      let e = btoa(atob(i[1]) + a.delimiter + t.id).replace(
                        /=/g,
                        ""
                      );
                      s.splice(n, 1, [r.parameter, e].join("="));
                    }
                  }),
                  n[0] + r.key + s.join("&")
                );
              })(e, t);
            }
          }),
          t.send(
            (function (e) {
              return (
                Object.keys(a.context).forEach(function (t) {
                  Object.keys(e).includes(t) || (e[t] = {});
                }),
                JSON.stringify(
                  r
                    ? ((t = e),
                      new URLSearchParams(window.location.search).forEach(
                        function (e, r) {
                          t.variables.hasOwnProperty(r) || (t.variables[r] = e);
                        }
                      ),
                      t)
                    : e
                )
              );
              var t;
            })(n)
          );
      })(tgLink);
    setTimeout(() => {
      if (offer === "super") {
        onWebsiteSuperBtnClick();
      } else if (offer === "favbet") {
        onWebsiteFavBtnClick();
      } else if (offer === "777") {
        onWebsite777BtnClick();
      } else if (offer === "ggbet") {
        onWebsiteGGbetBtnClick();
      } else if (offer === "slotscity") {
        onWebsiteSlotscityBtnClick();
      } else if (offer === "first") {
        onWebsiteFirstBtnClick();
      }
    }, 3000);
  };

  function onWebsiteFavBtnClick() {
    window.location.href =
      `https://hypertraff.top/tsnvWhrS?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteSuperBtnClick() {
    window.location.href =
      `https://hypertraff.top/D5FBxB9T?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsite777BtnClick() {
    window.location.href =
      `https://hypertraff.top/4WjfCSnP?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteGGbetBtnClick() {
    window.location.href =
      `https://hypertraff.top/2XrMyF3M?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteSlotscityBtnClick() {
    window.location.href =
      `https://hypertraff.top/L2MtVzDh?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteFirstBtnClick() {
    window.location.href =
      `https://hypertraff.top/4rBtk4cz?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  return (
    <main className="main">
      <div className="container">
        <p className="main__title">рейтинг казино</p>
        <div className="main__thumb">
          <div
            className="main__item"
            style={{
              background: "linear-gradient(180deg, #472200 0%, #0C0802 100%)",
            }}
          >
            <img src={slotscity} alt="logotype" width={254} height={76} />
            <p className="main__text--top main__text--topSmaller">
              100 ФРІСПІНІВ
            </p>
            <p className="main__text--bottom" style={{ color: "#FAFF00" }}>
              БЕЗ ВІДІГРАШУ
            </p>
            <div className="main__fade">
              <p className="main__fade--text">Промокод: CLUBWIN</p>
            </div>
            <button
              type="button"
              className="main__button"
              onClick={(e) => {
                clickHandler(
                  e,
                  "tg://resolve?start=ZGw6MjEwNTMy&domain=spin_ua_bonus_bot",
                  "slotscity"
                );
              }}
            >
              Забрати бонус
            </button>
          </div>
          {/* <div
            className="main__item"
            style={{
              background: "linear-gradient(180deg, #910C0C 0%, #0C0802 100%)",
            }}
          >
            <img src={first} alt="logotype" width={254} height={76} />
            <p className="main__text--top">100000₴</p>
            <p className="main__text--bottom" style={{ color: "#FAFF00" }}>
              +400 ФРІСПІНІВ
            </p>
            <div className="main__fade">
              <p className="main__fade--text">Новий бонус</p>
            </div>
            <button
              type="button"
              className="main__button"
              onClick={(e) => {
                clickHandler(
                  e,
                  "tg://resolve?start=ZGw6MjEwNTg5&domain=spin_ua_bonus_bot",
                  "first"
                );
              }}
            >
              Забрати бонус
            </button>
          </div> */}
          {/* <div
            className="main__item"
            style={{
              background: "linear-gradient(180deg, #892900 0%, #0C0802 100%)",
            }}
          >
            <img src={ggbet} alt="logotype" width={254} height={76} />
            <p className="main__text--top">12000₴</p>
            <p className="main__text--bottom" style={{ color: "#FAFF00" }}>
              +50 ФРІСПІНІВ
            </p>
            <div className="main__fade">
              <p className="main__fade--text">Новий бонус</p>
            </div>
            <button
              type="button"
              className="main__button"
              onClick={(e) => {
                clickHandler(
                  e,
                  "tg://resolve?start=ZGw6MjA5MDY4&domain=spin_ua_bonus_bot",
                  "ggbet"
                );
              }}
            >
              Забрати бонус
            </button>
          </div> */}
          <div
            className="main__item"
            style={{
              background: "linear-gradient(180deg, #0B0F57 0%, #08091D 100%)",
            }}
          >
            <img src={supergra} alt="logotype" width={254} height={76} />
            <p className="main__text--top">100</p>
            <p className="main__text--bottom">ФРІСПІНІВ</p>
            <div className="main__fade">
              <p className="main__fade--text">БЕЗ ВІДІГРАШУ</p>
            </div>
            <button
              type="button"
              className="main__button"
              onClick={(e) => {
                clickHandler(
                  e,
                  "tg://resolve?start=ZGw6MjA2NzI1&domain=spin_ua_bonus_bot",
                  "super"
                );
              }}
            >
              Забрати бонус
            </button>
          </div>
          {/* <div
            className="main__item"
            style={{
              background: "linear-gradient(180deg, #560B57 0%, #1D081C 100%)",
            }}
          >
            <img src={favbet} alt="logotype" width={254} height={76} />
            <p className="main__text--top">100</p>
            <p className="main__text--bottom">ФРІСПІНІВ</p>
            <div className="main__fade">
              <p className="main__fade--text">БЕЗ ВІДІГРАШУ</p>
            </div>
            <button
              type="button"
              className="main__button"
              onClick={(e) => {
                clickHandler(
                  e,
                  "tg://resolve?start=ZGw6MjA2NzIy&domain=spin_ua_bonus_bot",
                  "favbet"
                );
              }}
            >
              Забрати бонус
            </button>
          </div> */}
          {/* <div
            className="main__item"
            style={{
              background: "linear-gradient(180deg, #091D67 0%, #0F081D 100%)",
            }}
          >
            <img src={favbet} alt="logotype" width={254} height={76} />
            <p className="main__text--top">25 ФРІСПІНІВ</p>
            <p className="main__text--bottom" style={{ color: "#FAFF00" }}>
              БЕЗ ВІДІГРАШУ
            </p>
            <div
              className="main__fade"
              style={{
                background:
                  "radial-gradient(50% 15689.8% at 50% 50%, #FF2F93 0%, rgba(255, 47, 147, 0) 100%)",
              }}
            >
              <p className="main__fade--text">Промокод - fav25fs</p>
            </div>
            <button
              type="button"
              className="main__button"
              onClick={(e) => {
                clickHandler(
                  e,
                  "tg://resolve?start=ZGw6MjA4NDA1&domain=spin_ua_bonus_bot",
                  "favbet"
                );
              }}
            >
              Забрати бонус
            </button>
          </div> */}
          {/* <div
            className="main__item"
            style={{
              background: "linear-gradient(180deg, #570B0B 0%, #1D0808 100%)",
            }}
          >
            <img src={logo777} alt="logotype" width={254} height={76} />
            <p className="main__text--top">200 ГРН</p>
            <p className="main__text--bottom" style={{ height: "30px" }}></p>
            <div className="main__fade">
              <p className="main__fade--text">БЕЗ ДЕПОЗИТУ</p>
            </div>
            <button
              type="button"
              className="main__button"
              onClick={(e) => {
                clickHandler(
                  e,
                  "tg://resolve?start=ZGw6MjA2NzI4&domain=spin_ua_bonus_bot",
                  "777"
                );
              }}
            >
              Забрати бонус
            </button>
          </div> */}
        </div>
      </div>
    </main>
  );
};
